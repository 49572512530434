import React from 'react'

import Layout from '../../components/layout'
import { Services } from '../../components/services'
import Seo from '../../components/seo'
const LodzServices = () => {

    return (
        <Layout lodz>
            <Services lodz/>
        </Layout>
    )
}

export default LodzServices